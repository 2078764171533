import React from "react";

import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselCaption,
  CarouselIndicators,
  CarouselControl,
} from "reactstrap";

import Calculator from '../UI/Calculator';
import LabourList from '../UI/LabourList';


import slide1 from "../../assets/img/slide1.jpeg";
import slide2 from "../../assets/img/slide2.jpeg";
import slide3 from "../../assets/img/slide3.jpeg";

// function IndexHeader() {
class IndexHeader extends React.Component {
  static propTypes = {
    member: PropTypes.shape({
      email: PropTypes.string,
    }),
    error: PropTypes.string,
    success: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    onFormSubmit: PropTypes.func.isRequired,
  //  calculatorOptions: PropTypes.shape(),
    labourList: PropTypes.shape(),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    error: null,
    success: null,
    member: {},
  //  calculatorOptions: {},
    labourList: {},
    navbarCollapse: false,
    navbarColor: "navbar-transparent",
  }

  constructor(props) {
    super(props);
    this.state = {
      activeSlide: 0,
      animating: false,
      items: {
          "first" : {
            "text" : "We offer a wide range of electrical solutions in Sheffield and surrounding areas",
            "title" : ""
          },
          "second" : {
            "text" : "We offer installation of any type of indoor or outdoor lighting, garden lighting, full house rewiring, electrical appliance istallations, CCTV installations, fire and security alarm installations",
            "title" : ""
          },
          "third" : {
            "text" : "We are constantly following innovations in electrical industry and offer our customers the best solution according to everyone`s budget",
            "title" : ""
          }
        }
    };
  }

  goToIndex = (newIndex) => {
    const { animating } = this.state;
    if (animating) return;
    this.setState({ activeSlide: newIndex });
  }

  next = () => {
    const { slideTexts } = this.props;
    const { animating, activeSlide, items } = this.state;
    if (animating || !slideTexts) return;
    const nextIndex = activeSlide === Object.keys(slideTexts).length - 1 ? 0 : activeSlide + 1;
    this.setState({ activeSlide: nextIndex });
  }

  previous = () => {
    const { slideTexts } = this.props;
    const { animating, activeSlide, items } = this.state;
    if (animating || !slideTexts) return;
    const nextIndex = activeSlide === 0 ? Object.keys(slideTexts).length - 1 : activeSlide - 1;
    this.setState({ activeSlide: nextIndex });
  }

  render () {
    const { slideTexts, calculatorOptions, labourList } = this.props;
    const { items, activeSlide } = this.state;

    return (
      <>
      {slideTexts ? (
        <Carousel
          activeIndex={activeSlide}
          next={this.next}
          previous={this.previous}
          autoPlay={false}
          interval={10000}
        >
          <CarouselIndicators items={Object.keys(slideTexts)} activeIndex={activeSlide} onClickHandler={this.goToIndex} />
          {Object.keys(slideTexts).map((key, index) => {
            const imgId = index % 3;

            let imageSrc = slide1;

            if (imgId === 0) {
              imageSrc = slide1;
            } else if (imgId === 1) {
              imageSrc = slide2;
            } else {
              imageSrc = slide3;
            }

            return (
              <CarouselItem
                onExiting={() => this.setState({ animating: true })}
                onExited={() => this.setState({ animating: false })}
                key={key}
                style={{ fontSize: 25 }}
              >
                <div className="carousel-image" style={{ backgroundImage: `url(${imageSrc})` }} />
                <CarouselCaption captionText={slideTexts[key].text} captionHeader={slideTexts[key].title} />
              </CarouselItem>
            );
          })}
        </Carousel>
      ):(
        <Carousel
          activeIndex={activeSlide}
          next={this.next}
          previous={this.previous}
          autoPlay={false}
          interval={10000}
        >
          <CarouselIndicators items={Object.keys(items)} activeIndex={activeSlide} onClickHandler={this.goToIndex} />
          {Object.keys(items).map((key, index) => {
            const imgId = index % 3;

            let imageSrc = slide1;

            if (imgId === 0) {
              imageSrc = slide1;
            } else if (imgId === 1) {
              imageSrc = slide2;
            } else {
              imageSrc = slide3;
            }

            return (
              <CarouselItem
                onExiting={() => this.setState({ animating: true })}
                onExited={() => this.setState({ animating: false })}
                key={key}
                style={{ fontSize: 25 }}
              >
                <div className="carousel-image" style={{ backgroundImage: `url(${imageSrc})` }} />
                <CarouselCaption captionText={items[key].text} captionHeader={items[key].title} />
              </CarouselItem>
            );
          })}
        </Carousel>
      )}
      {/*  <div
          className="page-header section-dark presentation-image"
          style={{
            backgroundImage: "url(" + require("../../assets/img/background.jpg") + ")",
          }}
        >
          <div className="filter" />
          <div className="content-center">
            <Container>
              <div className="title-brand">
                <h1 className="presentation-title">Title</h1>
              </div>

            </Container>
          </div>
        </div> */}
        <Row className="our-services">
          <Col sm="3" className="our-services-1">
            <Link to="/residential" className="our-services-link">
              <div className="our-services-header">
                Residential Lighting
              </div>
              <div className="our-services-description">
                Indoor Residential Lighting & Electrical Solutions
              </div>
            </Link>
          </Col>

          <Col sm="3" className="our-services-2">
            <Link to="/commercial" className="our-services-link">
              <div className="our-services-header">
                Commercial Ligthing
              </div>
              <div className="our-services-description">
                Commercial Lighting & Electrical Solutions for interior and exterior
              </div>
            </Link>
          </Col>

          <Col sm="3" className="our-services-3">
            <Link to="/security" className="our-services-link">
              <div className="our-services-header">
                Security Systems
              </div>
              <div className="our-services-description">
                Commercial Security System Installations
              </div>
            </Link>
          </Col>
          <Col sm="3" className="our-services-4">
            <Link to="/maintenance" className="our-services-link">
              <div className="our-services-header">
                Maintenance
              </div>
              <div className="our-services-description">
                Support and Maintenance of installed systems
              </div>
              </Link>
          </Col>
        </Row>
        <Container className="home-body">
            <Col sm="12">
              <h2> About us </h2>
              <h1 className="home-description">  Our company is successfully running in Sheffield and surrounding areas for the past 7 years. We offer a wide range of electrical solutions. Our main goal is customer satisfaction. We are very flexible in terms of customer requests and needs. We are constantly following innovations in electrical industry and offer our customers the best solution according to everyone`s budget. By choosing LED lighting you are saving on energy bills and also reducing global carbon footprint. Please use "contact us" for any inquiries. </h1>
            </Col>
          {/*  {calculatorOptions ? (
              <Col sm="12">
                <Calculator calculatorOptions={calculatorOptions} />
              </Col>
            ):(null)} */}
            {labourList ? (
              <Col sm="12">
                <LabourList labourList={labourList} />
              </Col>
            ):(null)}
        </Container>
      </>
    );
  }
}


export default IndexHeader;
