import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Firebase } from '../lib/firebase';

import Page from '../web/components/Admin/ContactMessages';

class ContactMessages extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    fetchContactMessages: PropTypes.func.isRequired,
    member: PropTypes.shape({}),
    admin: PropTypes.shape({}),
  }

  static defaultProps = {
    member: {},
    admin: {},
  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    console.log('componentDidMount');
    const { fetchContactMessages, history } = this.props;

    Firebase.auth().onAuthStateChanged((loggedIn) => {
      if (loggedIn) {
        fetchContactMessages();
      } else {
        history.push('/');
      }
    });


  }


  render = () => {
    const { admin, member } = this.props;

  //  console.log(member);

  //  if (!member || !member.uid) {
  //    history.push('/');
  //  }

    return (
      <Page
        messages={admin.contactMessages}
        member={member}
      />
    );
  }
}

const mapStateToProps = state => ({
  admin: state.admin || {},
  member: state.member || {},
});

const mapDispatchToProps = dispatch => ({
  fetchContactMessages: dispatch.admin.fetchContactMessages,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactMessages);
