import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';

import ReactDOM from 'react-dom';

// import ColorPicker, { colorPickerPlugin } from 'draft-js-color-picker';

// import Editor from 'draft-js-plugins-editor';

// import createImagePlugin from 'draft-js-image-plugin';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import 'draft-js-image-plugin/lib/plugin.css';

import Location from '../Location';
import ReadOnlyEditor from './ReadOnlyEditor';

// const imagePlugin = createImagePlugin();


/*
// Custom overrides for "code" style.
      const styleMap = {
        CODE: {
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
          fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
          fontSize: 16,
          padding: 2,
        },
        STRIKETHROUGH: {
          textDecoration: 'line-through',
        },
      };

function getBlockStyle(block) {
        switch (block.getType()) {
          case 'blockquote': return 'RichEditor-blockquote';
          default: return null;
        }
      }

class StyleButton extends React.Component {
       constructor() {
         super();
         this.onToggle = (e) => {
           e.preventDefault();
           this.props.onToggle(this.props.style);
         };
       }

       render() {
         let className = 'RichEditor-styleButton';
         if (this.props.active) {
           className += ' RichEditor-activeButton';
         }

         return (
           <span className={className} onMouseDown={this.onToggle}>
             {this.props.label}
           </span>
         );
       }
     }

     const BLOCK_TYPES = [
       {label: 'H1', style: 'header-one'},
       {label: 'H2', style: 'header-two'},
       {label: 'H3', style: 'header-three'},
       {label: 'H4', style: 'header-four'},
       {label: 'H5', style: 'header-five'},
       {label: 'H6', style: 'header-six'},
       {label: 'Blockquote', style: 'blockquote'},
       {label: 'UL', style: 'unordered-list-item'},
       {label: 'OL', style: 'ordered-list-item'},
       {label: 'Code Block', style: 'code-block'},
     ];

     const BlockStyleControls = (props) => {
       const {editorState} = props;
       const selection = editorState.getSelection();
       const blockType = editorState
         .getCurrentContent()
         .getBlockForKey(selection.getStartKey())
         .getType();

       return (
         <div className="RichEditor-controls">
           {BLOCK_TYPES.map((type) =>
             <StyleButton
               key={type.label}
               active={type.style === blockType}
               label={type.label}
               onToggle={props.onToggle}
               style={type.style}
             />
           )}
         </div>
       );
     };

     var INLINE_STYLES = [
       {label: 'Bold', style: 'BOLD'},
       {label: 'Italic', style: 'ITALIC'},
       {label: 'Underline', style: 'UNDERLINE'},
       {label: 'STRIKETHROUGH', style: 'STRIKETHROUGH'},
       {label: 'Monospace', style: 'CODE'},
     ];


     const InlineStyleControls = (props) => {
       const currentStyle = props.editorState.getCurrentInlineStyle();

       return (
         <div className="RichEditor-controls">
           {INLINE_STYLES.map((type) =>
             <StyleButton
               key={type.label}
               active={currentStyle.has(type.style)}
               label={type.label}
               onToggle={props.onToggle}
               style={type.style}
             />
           )}
         </div>
       );
     };

     const presetColors = [
       '#ff00aa',
       '#F5A623',
       '#F8E71C',
       '#8B572A',
       '#7ED321',
       '#417505',
       '#BD10E0',
       '#9013FE',
       '#4A90E2',
       '#50E3C2',
       '#B8E986',
       '#000000',
       '#4A4A4A',
       '#9B9B9B',
       '#FFFFFF',
     ]; */

class Page extends Component {
  static propTypes = {
    getComments: PropTypes.func.isRequired,
    writeComment: PropTypes.func.isRequired,
    member: PropTypes.shape(),
    content: PropTypes.shape(),
    id: PropTypes.string,
  }

  static defaultProps = {
    content: null,
    member: {},
    id: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidMount() {
    const { id, content } = this.props;

    console.log(this.props);

    if (content) {
      console.log(content);

      const contentraw = convertFromRaw(content);

      if (contentraw) {
        this.setState({ editorState: EditorState.createWithContent(contentraw) });
      }
    }
  }

  updatePageContent = () => {
    const { updatePageContent, id } = this.props;
    const { editorState } = this.state;

    console.log('updatePageContent');

    if (editorState && id) {
      updatePageContent({ content: convertToRaw(editorState.getCurrentContent()), id });
    }
  }

  onChange = (editorState) => {
    this.setState({ editorState })
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { member, content, id } = this.props;
    const { editorState, color } = this.state;

    console.log(this.props);
    console.log(editorState);


    // If the user changes block type before entering any text, we can
          // either style the placeholder or hide it. Let's just hide it now.
          let className = 'RichEditor-editor';
          var contentState = editorState.getCurrentContent();

          if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
              className += ' RichEditor-hidePlaceholder';
            }
          }

    return (
      <>
        <Location id={id} />
        <Container className="page">
          {member && member.uid && (
            <Row>
              <Col xs="12">
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={this.onChange}
                />
                <Button color="primary" onClick={this.updatePageContent}>
                  Saglabāt
                </Button>
              </Col>
            </Row>
          )}
          <Row>
            {member && member.uid && (
              <Col xs="12">
                Content
              </Col>
            )}
            <Col xs="12">
              <ReadOnlyEditor content={content} />
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default Page;
