import React from 'react';
import { convertFromRaw, EditorState } from 'draft-js';

import { Editor } from 'react-draft-wysiwyg';

import 'draft-js/dist/Draft.css';

const ReadOnlyEditor = (props) => {
  console.log(props.content);
  if (props.content) {
    const contentraw = convertFromRaw(props.content);

    const content = EditorState.createWithContent(contentraw);
    return (
       <div className="readonly-editor">
         <Editor editorState={content} ReadOnly={true} readOnly={true} toolbarHidden={true} />
       </div>
    );
  } else {
    return null;
  }
}

export default ReadOnlyEditor;
