import React from "react";

// reactstrap components

import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import IndexNavbar from "../components/Navbars/IndexNavbar.js";
import IndexHeader from "../components/Headers/IndexHeader.js";
import Footer from "../components/Footers/Footer.js";

import About from '../components/About/About';
import Areas from '../components/About/Areas';

import Residential from '../components/WhatWeDo/Residential';
import Commercial from '../components/WhatWeDo/Commercial';
import Electrical from '../components/WhatWeDo/Electrical';
import Security from '../components/WhatWeDo/Security';
import Maintenance from '../components/WhatWeDo/Maintenance';

// import ContactUs from '../components/ContactUs/ContactUs';

import Location from '../components/Location';

function Page({ id, content }) {
  console.log(id);
  return (
    <>
      <IndexNavbar navbarColor="" />
      <Location id={id} />
      <Container className="page">
        <Row>
          <Col sm="12">
            {id === 'about-us' && (
              <About content={content} />
            )}
            {id === 'areas-we-work' && (
              <Areas content={content} />
            )}

            {id === 'residential' && (
              <Residential content={content} />
            )}
            {id === 'commercial' && (
              <Commercial content={content} />
            )}
          {/*  {id === 'electrical' && (
              <Electrical content={content} />
            )}  */}
            {id === 'security' && (
              <Security content={content} />
            )}
            {id === 'maintenance' && (
              <Maintenance content={content} />
            )}
          {/*  {id === 'contact-us' && (
              <ContactUs />
            )} */}

          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Page;
