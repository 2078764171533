import React from "react";

// reactstrap components
import { Row, Container, Col } from "reactstrap";

function Footer() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <Col sm="3">
            <div className="footer-header"> Our Services </div>
            <a className="footer-link" href="/residential"> Residential lighting </a>
            <a className="footer-link" href="/commercial"> Commercial lighting </a>
            <a className="footer-link" href="/security"> Fire & Security Systems </a>
          </Col>
          <Col sm="3">
            <div className="footer-header"> We work in South Yorkshire and surrounding areas </div>
          </Col>
          <Col sm="4">
            <div className="footer-header"> Aditional information </div>
            <a className="footer-link" href="/"> Home </a>
            <a className="footer-link" href="/about-us"> About Us </a>
          {/*  <a className="footer-link" href="/blog"> Blog </a> */}
            <a className="footer-link" href="/contact-us"> Contact Us </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
