import React from "react";
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import classnames from "classnames";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebookSquare } from '@fortawesome/free-brands-svg-icons'

import {
  Row,
  Col,
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavbarToggler,
  NavbarText,
  NavItem,
  NavLink,
  Nav,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";

  class IndexNavbar extends React.Component {
    static propTypes = {
      member: PropTypes.shape({
        email: PropTypes.string,
      }),
      error: PropTypes.string,
      success: PropTypes.string,
      loading: PropTypes.bool.isRequired,
      onFormSubmit: PropTypes.func.isRequired,
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
    }

    static defaultProps = {
      error: null,
      success: null,
      member: {},
      navbarCollapse: false,
      navbarColor: "navbar-transparent",
    }

    constructor(props) {
      super(props);
      this.state = {
        activeDropdown: null,
        activeMobileDropdown: null,
        isOpen: false,
      };
    }

    componentDidMount() {
      const { navbarColor, navbarCollapse } = this.state;
    }

    componentWillUnmount() {
    //  window.removeEventListener("scroll", updateNavbarColor);
    }

    toggle = () => {
      console.log('toggle');
      this.setState((prevState) => ({
        isOpen: !prevState.isOpen,
      }))
    }

    toggle1 = () => {
      const { activeDropdown } = this.state;
      console.log(activeDropdown);

      if (activeDropdown && activeDropdown === 'about') {
        this.setState({ activeDropdown: null });
      } else {
        this.setState({ activeDropdown: 'about' });
      }
    }

    toggle2 = () => {
      const { activeDropdown } = this.state;
      console.log(activeDropdown);

      if (activeDropdown && activeDropdown === 'whatWeDo') {
        this.setState({ activeDropdown: null });
      } else {
        this.setState({ activeDropdown: 'whatWeDo' });
      }
    }

    toggle3 = () => {
      const { activeMobileDropdown } = this.state;
      console.log(activeMobileDropdown);

      if (activeMobileDropdown && activeMobileDropdown === 'about') {
        this.setState({ activeMobileDropdown: null });
      } else {
        this.setState({ activeMobileDropdown: 'about' });
      }
    }

    toggle4 = () => {
      const { activeMobileDropdown } = this.state;
      console.log(activeMobileDropdown);

      if (activeMobileDropdown && activeMobileDropdown === 'whatWeDo') {
        this.setState({ activeMobileDropdown: null });
      } else {
        this.setState({ activeMobileDropdown: 'whatWeDo' });
      }
    }

    render () {
      const { activeDropdown, activeMobileDropdown, isOpen } = this.state;
      const { navbarColor } = this.props;

      console.log(isOpen);
 /*
      return (
        <Navbar color="dark" expand="md" style={{ width: '100%' }}>
        <NavbarBrand href="/">reactstrap</NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={isOpen} navbar style={{ right: 200, top: 60 }}>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="/components/">Components</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://github.com/reactstrap/reactstrap">GitHub</NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Options
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  Option 1
                </DropdownItem>
                <DropdownItem>
                  Option 2
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem>
                  Reset
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          <NavbarText>Simple Text</NavbarText>
        </Collapse>
      </Navbar>
    )  */

    return (
      <Navbar className={classnames("fixed-top")} expand="md">

            <NavbarBrand
              className="Choice-of-LED-lighting-logo"
              data-placement="bottom"
              href="/"
              title="Choice of LED lighting"
            >
              Choice of LED lighting
            </NavbarBrand>
            <NavbarToggler className={`d-block d-md-none`} onClick={this.toggle}>

              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />

            </NavbarToggler>
          <Collapse
            className={`d-none d-md-block justify-content-end navbar-link-nav-collapse`}
          >
            <Nav navbar className="navbar-link-nav">
              <NavItem>
                <NavLink
                  data-placement="bottom"
                  href="https://twitter.com"
                  target="_blank"
                  title="Follow us on Twitter"
                  style={{ paddingLeft: 2, paddingRight: 2, color: '#2b2a28' }}
                >
                  <FontAwesomeIcon icon={faTwitter} style={{ marginRight: 5 }} />
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  data-placement="bottom"
                  href="https://www.facebook.com"
                  target="_blank"
                  title="Like us on Facebook"
                  style={{ paddingLeft: 2, paddingRight: 2, color: '#2b2a28' }}
                >
                  <FontAwesomeIcon icon={faFacebookSquare} style={{ marginRight: 5 }} />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={`navbar-link ${navbarColor === 'navbar-transparent' ? 'navbar-link-noBorder' : ''}`} href="/">
                  <i className="nc-icon nc-book-bookmark" />
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <Dropdown className="navbar-dropdown" isOpen={activeDropdown === 'about'} toggle={this.toggle1}>
                  <DropdownToggle className={`navbar-dropdown-toggle`}>
                    About Us
                  </DropdownToggle>
                  <DropdownMenu className="navbar-dropdown-menu">
                    <DropdownItem className="navbar-dropdown-item">
                      <a className="navbar-dropdown-link" href="/about-us">
                        About Us
                      </a>
                    </DropdownItem>
                    <DropdownItem className="navbar-dropdown-item">
                      <a className="navbar-dropdown-link" href="/areas-we-work">
                        Areas we work in
                      </a>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
              <NavItem>
              <Dropdown className="navbar-dropdown" isOpen={activeDropdown === 'whatWeDo'} toggle={this.toggle2}>
                <DropdownToggle className={`navbar-dropdown-toggle`}>
                  What We Do
                </DropdownToggle>
                <DropdownMenu className="navbar-dropdown-menu">
                  <DropdownItem className="navbar-dropdown-item">
                    <a className="navbar-dropdown-link" href="/residential">
                      Residential Lighting
                    </a>
                  </DropdownItem>
                  <DropdownItem className="navbar-dropdown-item">
                    <a className="navbar-dropdown-link" href="/commercial">
                      Commercial lighting
                    </a>
                  </DropdownItem>
                  <DropdownItem className="navbar-dropdown-item">
                    <a className="navbar-dropdown-link" href="/security">
                      Fire & Security Systems
                    </a>
                  </DropdownItem>
                  <DropdownItem className="navbar-dropdown-item">
                    <a className="navbar-dropdown-link" href="/maintenance">
                      Maintenance
                    </a>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              </NavItem>
              <NavItem>
                <NavLink className={`navbar-link ${navbarColor === 'navbar-transparent' ? 'navbar-link-noBorder' : ''}`} href="/gallery">
                  <i className="nc-icon nc-book-bookmark" /> Gallery
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={`navbar-link ${navbarColor === 'navbar-transparent' ? 'navbar-link-noBorder' : ''}`} href="/comments">
                  <i className="nc-icon nc-book-bookmark" /> Comments
                </NavLink>
              </NavItem>
              <NavItem>
                <Button
                  className="btn-round"
                  color="danger"
                  href="/contact-us"
                >
                  Contact Us
                </Button>
              </NavItem>
            </Nav>
          </Collapse>


          <Collapse
            isOpen={isOpen}
            className={`d-block d-md-none justify-content-end mobile-navbar ${isOpen ? '' : 'mobile-navbar-closed'}`}
          >
            <Nav navbar className="mobile-navbar-nav">
              <Row>
              <Col xs="3" className="mobile-navbar-link-social">
              <NavItem>
                <NavLink
                  className="mobile-navbar-link"
                  data-placement="bottom"
                  href="https://twitter.com"
                  target="_blank"
                  title="Follow us on Twitter"
                  style={{ paddingLeft: 2, paddingRight: 2, color: '#2b2a28' }}
                >
                  <FontAwesomeIcon icon={faTwitter} style={{ marginRight: 5 }} />
                </NavLink>
              </NavItem>
              </Col>
              <Col xs="3">
              <NavItem>
                <NavLink
                  className="mobile-navbar-link"
                  data-placement="bottom"
                  href="https://www.facebook.com"
                  target="_blank"
                  title="Like us on Facebook"
                  style={{ paddingLeft: 2, paddingRight: 2, color: '#2b2a28' }}
                >
                  <FontAwesomeIcon icon={faFacebookSquare} style={{ marginRight: 5 }} />
                </NavLink>
              </NavItem>
              </Col>
              </Row>
              <NavItem>
                <NavLink className={`mobile-navbar-link ${navbarColor === 'navbar-transparent' ? 'navbar-link-noBorder' : ''}`} href="/">
                  <i className="nc-icon nc-book-bookmark" />
                  Home
                </NavLink>
              </NavItem>
              <NavbarText className="mobile-navbar-link" onClick={this.toggle3}>About Us</NavbarText>
              {activeMobileDropdown === 'about' ? (
                <>
                  <NavItem>
                    <NavLink className={`mobile-navbar-link mobile-navbar-link-sub ${navbarColor === 'navbar-transparent' ? 'navbar-link-noBorder' : ''}`} href="/about-us">
                      <i className="nc-icon nc-book-bookmark" />
                      About Us
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={`mobile-navbar-link mobile-navbar-link-sub ${navbarColor === 'navbar-transparent' ? 'navbar-link-noBorder' : ''}`} href="/areas-we-work">
                      <i className="nc-icon nc-book-bookmark" />
                      Areas we work in
                    </NavLink>
                  </NavItem>
                </>
              ):(null)}

              <NavbarText className="mobile-navbar-link" onClick={this.toggle4}>What We Do</NavbarText>
              {activeMobileDropdown === 'whatWeDo' ? (
                <>
                  <NavItem>
                    <NavLink className={`mobile-navbar-link mobile-navbar-link-sub ${navbarColor === 'navbar-transparent' ? 'navbar-link-noBorder' : ''}`} href="/residential">
                      <i className="nc-icon nc-book-bookmark" />
                      Residential Lighting
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={`mobile-navbar-link mobile-navbar-link-sub ${navbarColor === 'navbar-transparent' ? 'navbar-link-noBorder' : ''}`} href="/commercial">
                      <i className="nc-icon nc-book-bookmark" />
                      Commercial lighting
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={`mobile-navbar-link mobile-navbar-link-sub ${navbarColor === 'navbar-transparent' ? 'navbar-link-noBorder' : ''}`} href="/security">
                      <i className="nc-icon nc-book-bookmark" />
                      Fire & Security Systems
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={`mobile-navbar-link mobile-navbar-link-sub ${navbarColor === 'navbar-transparent' ? 'navbar-link-noBorder' : ''}`} href="/maintenance">
                      <i className="nc-icon nc-book-bookmark" />
                      Maintenance
                    </NavLink>
                  </NavItem>
                </>
              ):(null)}

              <NavItem>
                <NavLink className={`mobile-navbar-link ${navbarColor === 'navbar-transparent' ? 'navbar-link-noBorder' : ''}`} href="/gallery">
                  <i className="nc-icon nc-book-bookmark" /> Gallery
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={`mobile-navbar-link ${navbarColor === 'navbar-transparent' ? 'navbar-link-noBorder' : ''}`} href="/comments">
                  <i className="nc-icon nc-book-bookmark" /> Comments
                </NavLink>
              </NavItem>
              <NavItem>
                <Button
                  className="btn-round"
                  color="danger"
                  href="/contact-us"
                >
                  Contact Us
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
      </Navbar>
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
});

const mapDispatchToProps = dispatch => ({
  memberLogout: dispatch.member.logout,
  fetchMember: dispatch.member.getMemberData,
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexNavbar);
