import { Firebase, FirebaseRef } from '../lib/firebase';
// import initState from '../store/game';

export default {
  /**
   *  Initial state
   */
  state: {
    comments: {},
  },

  /**
   * Reducers
   */
  reducers: {
    setComments(state, payload) {
      return {
        ...state,
        comments: payload,
      };
    },
    setImages(state, payload) {
      return {
        ...state,
        images: payload,
      };
    },

  },

  /**
   * Effects/Actions
   */
  effects: () => ({
    /**
     * Get Meals
     *
     * @return {Promise}
     */
    getComments() {
      if (Firebase === null) return () => new Promise(resolve => resolve());

      return new Promise((resolve, reject) => {
        FirebaseRef.child(`comments`).once('value')
        .then((snapshot) => {
          const comments = snapshot.val() || {};

          this.setComments({ ...comments });
        }).catch((err) => {
          console.log(err);
          return reject();
        });
      })
    },


    writeComment({ name, comment }) {
      if (Firebase === null && name && comment) return () => new Promise(resolve => resolve());

      console.log('writeComment');
      console.log(comment);
      console.log(name);

      return new Promise((resolve, reject) => {
        const writeCommentFunction = Firebase.app().functions().httpsCallable('writeComment');

          writeCommentFunction({
            text: comment,
            name,
          }).then(res => resolve(res)).catch((err) => {
            console.log(err);
            return reject(err);

          });
      })
    },

    sendContactMessage({ name, email, phone, message }) {
      if (Firebase === null || !name || !(email || phone) || !message) return () => new Promise(resolve => resolve());

      console.log('sendContactMessage');
      console.log(name);
      console.log(email);
      console.log(message);

      return new Promise((resolve, reject) => {
        const sendContactFunction = Firebase.app().functions().httpsCallable('sendContact');

          sendContactFunction({
            name,
            email,
            phone,
            message,
          }).then(res => resolve(res)).catch((err) => {
            console.log(err);
            return reject(err);

          });
      })
    }

  }),
}
