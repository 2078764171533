import { Firebase, FirebaseRef } from '../lib/firebase';
// import initState from '../store/game';

import { v4 as uuidv4 } from 'uuid';

export default {
  /**
   *  Initial state
   */
  state: {
    galleries: {},
    gallery: {},
  },

  /**
   * Reducers
   */
  reducers: {
    setGalleries(state, payload) {
      return {
        ...state,
        galleries: payload,
      };
    },
    setGalleries2(state, payload) {
      return {
        ...state,
        gallery: payload,
      };
    },
    setImages(state, payload) {
      return {
        ...state,
        images: payload,
      };
    },

  },

  /**
   * Effects/Actions
   */
  effects: () => ({
    /**
     * Get Meals
     *
     * @return {Promise}
     */
    getGalleries() {
      console.log('getGalleries');
      if (Firebase === null) return () => new Promise(resolve => resolve());

      console.log('getGalleries 2');
      return new Promise((resolve, reject) => {
        FirebaseRef.child(`galleries`).once('value')
        .then((snapshot) => {
          const galleries = snapshot.val() || {};

          console.log('galleries');
          console.log(galleries);

          this.setGalleries({ ...galleries });
        }).catch((err) => {
          console.log(err);
          return reject();
        });
      })
    },


    getGallery({ id }) {
      if (Firebase === null && id) return () => new Promise(resolve => resolve());

      console.log('getGallery');
      console.log(id);

      return new Promise((resolve, reject) => {
        FirebaseRef.child(`images/${id}`).once('value')
        .then((snapshot) => {
          const images = snapshot.val();

          console.log(images);

          this.setImages({ ...images });
        }).catch(reject);
      })
    },


    getGalleries2() {
      if (Firebase === null) return () => new Promise(resolve => resolve());

      console.log('getGalleries2 2');
      return new Promise((resolve, reject) => {
        FirebaseRef.child(`gallery`).once('value')
        .then((snapshot) => {
          const gallery = snapshot.val() || {};

          console.log('gallery');
          console.log(gallery);

          this.setGalleries2({ ...gallery });
        }).catch((err) => {
          console.log(err);
          return reject();
        });
      })
    },

    addImage(files) {
      console.log('addImage');
      console.log(files);

      if (Firebase === null || !files) return () => new Promise(resolve => resolve());

      const storageRef = Firebase.storage().ref();

      files.map((file, index) => {
        console.log('file');
        console.log(file);

        const name = uuidv4();

        let type = 'jpg';
        if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
          type = 'jpg';
        } else if (file.type === 'image/gif') {
          type = 'gif';
        } else if (file.type === 'image/png') {
          type = 'png';
        }

        if (type) {
          const galleryRef = storageRef.child(`images/${name}.${type}`);

          galleryRef.put(file).then((snapshot) => {
            console.log('Uploaded a blob or file!');

            snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log('File available at', downloadURL);

              FirebaseRef.child(`gallery`).push(downloadURL);
            });

          });

        }
      })

    //  return new Promise((resolve, reject) => {


    //    galleryRef.put(file).then((snapshot) => {
    //      console.log('Uploaded a blob or file!');
    //    });
    //  })
    },


    resetGameStore() {
      return new Promise((resolve, reject) => {
        this.resetRoom();

        resolve();
      });
    },

  }),
}
