import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Templates
import TemplateNothing from '../components/Templates/Nothing';
// import TemplateSidebar from '../components/Templates/Sidebar';

// Routes
import Home from '../views/Index';
import Page from '../views/Page';

import PageContainer from '../../containers/Page';
import HomeContainer from '../../containers/Home';

import ContactMessages from '../../containers/ContactMessages';

import Member from '../../containers/Member';
import MemberComponent from '../components/Member';

import IndexNavbar from "../components/Navbars/IndexNavbar";
import Footer from "../components/Footers/Footer";

import GalleryContainer from '../../containers/Gallery';
import GalleryComponent from '../components/Gallery/Gallery';

import CommentsContainer from '../../containers/Comments';
import CommentsComponent from '../components/Comments/Comments';

import ContactUsContainer from '../../containers/ContactUs';
import ContactUsComponent from '../components/ContactUs/ContactUs';

import LoginContainer from '../../containers/Login';
import LoginComponent from '../components/User/Login';


import Error from '../components/UI/Error';

const Index = () => (
  <>
  <div className="page-wrapper">
  <Member Layout={MemberComponent} />
  <Switch>
    <Route
      exact
      path="/"
      render={props => (
        <TemplateNothing pageTitle="Home">
          <HomeContainer {...props} />
        </TemplateNothing>
      )}
    />

    <Route
      exact
      path="/about-us"
      render={props => (
        <TemplateNothing pageTitle="About Us">
          <IndexNavbar navbarColor="" />
          <PageContainer {...props} id="about-us" />
        </TemplateNothing>
      )}
    />

    <Route
      exact
      path="/areas-we-work"
      render={props => (
        <TemplateNothing pageTitle="Areas We Work">
        {/*  <Page id="areas-we-work" /> */}
          <IndexNavbar navbarColor="" />
          <PageContainer {...props} id="areas-we-work" />
        </TemplateNothing>
      )}
    />

    <Route
      exact
      path="/residential"
      render={props => (
        <TemplateNothing pageTitle="Residential">
        {/*  <Page id="residential" /> */}
          <IndexNavbar navbarColor="" />
          <PageContainer {...props} id="residential" />
        </TemplateNothing>
      )}
    />

    <Route
      exact
      path="/commercial"
      render={props => (
        <TemplateNothing pageTitle="Commercial">
        {/*  <Page id="commercial" />  */}
          <IndexNavbar navbarColor="" />
          <PageContainer {...props} id="commercial" />
        </TemplateNothing>
      )}
    />

    <Route
      exact
      path="/electrical"
      render={props => (
        <TemplateNothing pageTitle="Electrical">
          <IndexNavbar navbarColor="" />
          <PageContainer {...props} id="electrical" />
        </TemplateNothing>
      )}
    />

    <Route
      exact
      path="/security"
      render={props => (
        <TemplateNothing pageTitle="Security">
          <IndexNavbar navbarColor="" />
          <PageContainer {...props} id="security" />
        </TemplateNothing>
      )}
    />

    <Route
      exact
      path="/maintenance"
      render={props => (
        <TemplateNothing pageTitle="Maintenance">
          <IndexNavbar navbarColor="" />
          <PageContainer {...props} id="maintenance" />
        </TemplateNothing>
      )}
    />

    <Route
      exact
      path="/contact-us"
      render={props => (
        <TemplateNothing pageTitle="Contact Us">
          <IndexNavbar navbarColor="" />
          <ContactUsContainer {...props} Layout={ContactUsComponent} />
        </TemplateNothing>
      )}
    />

    <Route
      path="/gallery"
      render={props => (
        <TemplateNothing pageTitle="Gallery">
          <IndexNavbar navbarColor="" />
          <GalleryContainer {...props} Layout={GalleryComponent} />
        </TemplateNothing>
      )}
    />

    <Route
      path="/comments"
      render={props => (
        <TemplateNothing pageTitle="Comments">
          <IndexNavbar navbarColor="" />
          <CommentsContainer {...props} Layout={CommentsComponent} />
        </TemplateNothing>
      )}
    />

    <Route
      path="/site-login"
      render={props => (
        <TemplateNothing pageTitle="Login">
          <IndexNavbar navbarColor="" />
          <LoginContainer {...props} Layout={LoginComponent} />
        </TemplateNothing>
      )}
    />

    <Route
      path="/admin-contactMessages"
      render={props => (
        <TemplateNothing pageTitle="Contact Messages">
          <IndexNavbar navbarColor="" />
          <ContactMessages {...props} />
        </TemplateNothing>
      )}
    />



    <Route
      render={props => (
        <TemplateNothing pageTitle="404 - Page not found">
          <IndexNavbar navbarColor="" />
          <Error {...props} title="404" content="Sorry, the route you requested does not exist" />
        </TemplateNothing>
      )}
    />
  </Switch>

  </div>
  <Footer />
  </>
);

export default Index;
