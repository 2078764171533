import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Page from '../web/components/Page/Page';
import Helmet from '../web/components/UI/HelmetWrapper';

class PageContainer extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    fetchPageContent: PropTypes.func.isRequired,
    member: PropTypes.shape({}),
    page: PropTypes.shape({}),
  }

  static defaultProps = {
    member: {},
    page: {},
  }

  constructor(props) {
    super(props);

    this.state = {
    //  error: null,
    //  loading: false,
    };
  }

  componentDidMount() {
    console.log('componentDidMount');
    const { id, fetchPageContent } = this.props;

    console.log('fetchPageContent');

  //  fetchMember();
    fetchPageContent(id).then(() => {
      this.setState({ contentLoaded: true });
    }).catch(err => {
      this.setState({ contentLoaded: true });
    })
  }

  componentDidUpdate (nextProps) {
  }

  render = () => {
    const { Layout, id, page, updatePageContent, fetchPageContent, member } = this.props;
    const { contentLoaded } = this.state;

    console.log(contentLoaded);

    console.log(id);
    console.log(page);

    if (contentLoaded) {
      return (
        <>
          <Helmet id={id} />
          <Page
            id={id}
            content={page.content[id]}
            updatePageContent={updatePageContent}
            fetchPageContent={fetchPageContent}
            member={member}
          />
        </>
      );
    }

    return (
      <div style={{ minHeight: '100vh' }}/>
    );
  }
}

const mapStateToProps = state => ({
  page: state.page || {},
  member: state.member || {},
});

const mapDispatchToProps = dispatch => ({
  fetchPageContent: dispatch.page.fetchPageContent,
  updatePageContent: dispatch.admin.updatePageContent,
//  fetchMember: dispatch.member.getMemberData,
});

export default connect(mapStateToProps, mapDispatchToProps)(PageContainer);
