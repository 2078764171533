import { Firebase, FirebaseRef } from '../lib/firebase';

export default {
  /**
   *  Initial state
   */
  state: {
    content: {},
  },

  /**
   * Reducers
   */
  reducers: {
    setContactMessages(state, payload) {
      return {
        ...state,
        contactMessages: payload.messages,
      };
    },

  },

  /**
   * Effects/Actions
   */
  effects: () => ({
    /**
     * Get Meals
     *
     * @return {Promise}
     */
    fetchContactMessages() {
      console.log('fetchContactMessages');
      if (Firebase === null) return () => new Promise(resolve => resolve());

      return new Promise((resolve, reject) => {
        FirebaseRef.child(`contactMessages`).once('value')
        .then((snapshot) => {
          const messages = snapshot.val() || null;

          console.log(messages);

          this.setContactMessages({ messages });
          return resolve();
        }).catch((err) => {
          console.log(err);
          return reject();
        });
      })
    },


    updatePageContent({ id, content }) {
      if (Firebase === null && id && content) return () => new Promise(resolve => resolve());

      console.log('updatePageContent');
      console.log(id);
      console.log(content);

      return new Promise((resolve, reject) => {
        FirebaseRef.child(`pageContent/${id}`).set(content)
        .then(res => resolve(res))
        .catch((err) => {
          console.log(err);
          return reject(err);
        });

      })
    }

  }),
}
