import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import Helmet from '../web/components/UI/HelmetWrapper';

class Gallery extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    getGalleries: PropTypes.func.isRequired,
    getGalleries2: PropTypes.func.isRequired,
    getGallery: PropTypes.func.isRequired,
    gallery: PropTypes.shape({}),
  }

  static defaultProps = {
    member: {},
  }

  constructor(props) {
    super(props);

    this.state = {
    //  error: null,
    //  loading: false,
    };
  }

  componentDidMount = () => {
  }

  componentDidUpdate (nextProps) {
  }

  addImage = (files) => {
    const { addImage } = this.props;

    console.log('addImage');
    console.log(files);

    addImage(files);
  }

  render = () => {
    const { Layout, gallery, getGallery, getGalleries, getGalleries2, member, addImage } = this.props;

    return (
      <>
        <Helmet id="gallery" />
        <Layout
          galleries={gallery.galleries}
          gallery={gallery.gallery}
          images={gallery.images}
          readGallery={getGallery}
          readGalleries={getGalleries2}
          addImage={addImage}
          member={member}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return ({
    gallery: state.gallery || {},
    member: state.member || null,
  })
};

const mapDispatchToProps = dispatch => ({
  getGalleries: dispatch.gallery.getGalleries,
  getGallery: dispatch.gallery.getGallery,
  getGalleries2: dispatch.gallery.getGalleries2,
  addImage: dispatch.gallery.addImage,
});

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
