import { Firebase, FirebaseRef } from '../lib/firebase';

export default {
  /**
   *  Initial state
   */
  state: {
    content: {},
  },

  /**
   * Reducers
   */
  reducers: {
    setPageContent(state, payload) {
      const { id, content } = payload;
      return {
        ...state,
        content: {
          ...state.content,
          [id]: content,
        }
      };
    },

    setSlideTexts(state, payload) {
      return {
        ...state,
        slideTexts: payload.content,
      };
    },

    setCalculatorOptions(state, payload) {
      return {
        ...state,
        calculatorOptions: payload.content,
      };
    },

    setLabourList(state, payload) {
      return {
        ...state,
        labourList: payload.content,
      };
    },

  },

  /**
   * Effects/Actions
   */
  effects: () => ({
    /**
     * Get Meals
     *
     * @return {Promise}
     */
    fetchPageContent(id) {
      console.log('fetchPageContent');
      console.log(id);
      if (Firebase === null || !id) return () => new Promise(resolve => resolve());

      return new Promise((resolve, reject) => {
        FirebaseRef.child(`pageContent/${id}`).once('value')
        .then((snapshot) => {
          const content = snapshot.val() || null;

          let parsedContent = { ...content };
          if (content && !content.entityMap) {
            parsedContent.entityMap = {};
          }

          this.setPageContent({ content: content ? parsedContent : null, id });
          return resolve();
        }).catch((err) => {
          console.log(err);
          return reject();
        });
      })
    },

    fetchSlideTexts() {
      console.log('fetchSlideTexts');
      if (Firebase === null) return () => new Promise(resolve => resolve());

      return new Promise((resolve, reject) => {
        FirebaseRef.child(`slideTexts`).once('value')
        .then((snapshot) => {
          const content = snapshot.val() || null;

          this.setSlideTexts({ content });
          return resolve();
        }).catch((err) => {
          console.log(err);
          return reject();
        });
      })
    },

    fetchCalculatorOptions() {
      console.log('fetchCalculatorOptions');
      if (Firebase === null) return () => new Promise(resolve => resolve());

      return new Promise((resolve, reject) => {
        FirebaseRef.child(`calculatorOptions`).once('value')
        .then((snapshot) => {
          const content = snapshot.val() || null;

          this.setCalculatorOptions({ content });
          return resolve();
        }).catch((err) => {
          console.log(err);
          return reject();
        });
      })
    },

    fetchLabourList() {
      console.log('fetchLabourList');
      if (Firebase === null) return () => new Promise(resolve => resolve());

      return new Promise((resolve, reject) => {
        FirebaseRef.child(`labourList`).once('value')
        .then((snapshot) => {
          const content = snapshot.val() || null;

          this.setLabourList({ content });
          return resolve();
        }).catch((err) => {
          console.log(err);
          return reject();
        });
      })
    },


  /*  updatePageContent({ id, content }) {
      if (Firebase === null && id && content) return () => new Promise(resolve => resolve());

      console.log('updatePageContent');
      console.log(id);
      console.log(content);

      return new Promise((resolve, reject) => {
        FirebaseRef.child(`pageContent/${id}`).set(content)
        .then(res => resolve(res))
        .catch((err) => {
          console.log(err);
          return reject(err);
        });


      /*  const updatePageContent = Firebase.app().functions().httpsCallable('updatePageContent');

          updatePageContent({
            id,
            content,
          }).then(res => resolve(res)).catch((err) => {
            console.log(err);
            return reject(err);

          }); */
  /*    })
    } */

  }),
}
