import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Row,
  Col,
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from 'reactstrap';

import ImageUploader from 'react-images-upload';

import Location from '../Location';

class Header extends Component {
  static propTypes = {
    galleries: PropTypes.shape(),
    images: PropTypes.shape(),
  }

  static defaultProps = {
    member: {},
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      pictures: [],
    };
  }

  componentDidMount() {
    const { readGalleries } = this.props;

    readGalleries();
  }

  toggleGallery = (img) => {
  //  const { readGallery } = this.props;
    const { isOpen } = this.state;
    console.log(img);
  //  if (!isOpen) {
    //  readGallery({ id });
  //  }
    this.setState({ isOpen: !isOpen, img: isOpen ? null : img });
  }

  onDrop = (picture) => {
    this.setState({
      pictures: this.state.pictures.concat(picture),
    });
  }

  uploadImages = () => {
    console.log('uploadImages');
    const { addImage, member } = this.props;
    const { pictures } = this.state;

    console.log(member);
    console.log(pictures);
    console.log(pictures.length);

    if (member && member.uid && pictures && pictures.length) {
      console.log('add');
      addImage(pictures);
    }
  }

  render() {
    const { member, galleries, images, gallery } = this.props;
    const { isOpen, img, pictures } = this.state;

    console.log(pictures);

    return (
      <>
      <Location id='gallery' />
      <Container className="gallery">
        {member && member.uid && (
          <>
            <ImageUploader
              withIcon={true}
              buttonText='Choose images'
              onChange={this.onDrop}
              imgExtension={['.jpg', '.gif', '.png']}
              maxFileSize={5242880}
            />

            {pictures && pictures.length ? (
              <Button className="btn-round btn btn-danger gallery-upload" onClick={this.uploadImages} > Upload </Button>
            ): null}
          </>
        )}
        <Row>
          {gallery && Object.keys(gallery).map((key) => (
            <Col onClick={() => this.toggleGallery(gallery[key])} lg="3" md="4" sm="6" className="gallery-item">
              <div className="gallery-item-image" style={{ backgroundImage: `url(${gallery[key] })` }} />
            </Col>
          ))}
        </Row>
        <Modal className="gallery-modal" size="lg" isOpen={isOpen} toggle={this.toggleGallery}>
          <ModalHeader toggle={this.toggleGallery}> </ModalHeader>
          <ModalBody>
            <Media className="gallery-modal-image" src={img} />
          </ModalBody>
        </Modal>
      {/*  <Modal className="gallery-modal" size="lg" isOpen={isOpen} toggle={this.toggleGallery}>
          <ModalHeader toggle={this.toggleGallery}> </ModalHeader>
          <ModalBody>
            <Row>
              {images && Object.keys(images).map((key) => (
                <Col onClick={this.toggleGallery} md="3" sm="6" className="gallery-modal-item">
                  <div className="gallery-modal-image" style={{ backgroundImage: `url(${images[key].img })` }} />
                </Col>
              ))}
            </Row>
          </ModalBody>
        </Modal>  */}
      </Container>
      </>
    );
  }
}

export default Header;
