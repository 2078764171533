import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';

class ContactMessages extends Component {
  static propTypes = {
    writeComment: PropTypes.func.isRequired,
    member: PropTypes.shape(),
    messages: PropTypes.shape(),
  }

  static defaultProps = {
    messages: {},
    member: {},
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
  }

  sendMessage = () => {
    const { sendMessage } = this.props;
    const { name, email, phone, messsage } = this.state;
    if (name && (email || phone) && messsage) {
      sendMessage({ name, email, phone, messsage });
    }
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { member, messages } = this.props;

    console.log(messages);

    return (
      <>
      <Container className="comments">
      <Row>
        <Col sm="12" style={{ textAlign: 'center' }}>
          <p> Contact page </p>
          <Row>
            <Col sm="2" style={{ border: '1px solid #000' }}>
              Name
            </Col>
            <Col sm="2" style={{ border: '1px solid #000' }}>
              Email
            </Col>
            <Col sm="2" style={{ border: '1px solid #000' }}>
              Phone
            </Col>
            <Col sm="6" style={{ border: '1px solid #000' }}>
              Message
            </Col>
          </Row>
          {messages && Object.keys(messages).map((key, index) => (
            <Row>
              <Col sm="2" style={{ border: '1px solid #000' }}>
                {messages[key].name}
              </Col>
              <Col sm="2" style={{ border: '1px solid #000' }}>
                {messages[key].email}
              </Col>
              <Col sm="2" style={{ border: '1px solid #000' }}>
                {messages[key].phone}
              </Col>
              <Col sm="6" style={{ border: '1px solid #000' }}>
                {messages[key].message}
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      </Container>
      </>
    )
  }
}

export default ContactMessages;
