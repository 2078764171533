import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Helmet from '../web/components/UI/HelmetWrapper';

class ContactUs extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    sendMessage: PropTypes.func.isRequired,
    gallery: PropTypes.shape({}),
  }

  static defaultProps = {
    member: {},
  }

  constructor(props) {
    super(props);

    this.state = {
    //  error: null,
    //  loading: false,
    };
  }

  componentDidMount = () => {
  }

  componentDidUpdate (nextProps) {
  }

  render = () => {
    const { Layout, sendMessage } = this.props;

    return (
      <>
        <Helmet id="contactUs" />
        <Layout
          sendMessage={sendMessage}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return null;
};

const mapDispatchToProps = dispatch => ({
  sendMessage: dispatch.comments.sendContactMessage,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
