import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import { Helmet } from "react-helmet";

class HelmetWrapper extends Component {
  static propTypes = {
    member: PropTypes.shape({
      firstName: PropTypes.string,
      email: PropTypes.string,
    })
  }

  static defaultProps = {
    member: {},
  }

  constructor(props) {
    super(props);

    const { id } = this.props;

    let description = 'Choice of LED lighting is successfully running in South Yorkshire. We offer a wide range of electrical solutions. By choosing LED lighting you are saving on energy bills and also reducing global carbon footprint.';
    let title = 'Choice of LED lighting';

    if (id === 'about-us') {
      title = 'Choice of LED lighting - About Us';
    } else if (id === 'residential') {
      description = 'We offer installation of any type of indoor or outdoor lighting, garden lighting, full house rewiring, electrical appliance istallations, CCTV installations, fire and security alarm installations in South Yorkshire.';
      title = 'Choice of LED lighting - Residential';
    } else if (id === 'commercial') {
      description = 'We offer indoor and outdoor light installations, sign lighting as well as CCTV installations. Fire alarms, emergency exit lights, electrical appliance installations in South Yorkshire.';
      title = 'Choice of LED lighting - Commercial';
    } else if (id === 'security') {
      description = 'We offer istallations of domestic or commercial  CCTV  systems, fire alarm systems, emergency exit lighting, burglar alarms.';
      title = 'Choice of LED lighting - Fire & Security Systems';
    } else if (id === 'residential') {
      description = 'We offer installation of any type of indoor or outdoor lighting, garden lighting, full house rewiring, electrical appliance istallations, CCTV installations, fire and security alarm installations.';
      title = 'Choice of LED lighting - Residential';
    } else if (id === 'gallery') {
      title = 'Choice of LED lighting - Gallery';
    } else if (id === 'contactUs') {
      title = 'Choice of LED lighting - Contact Us';
      description = 'Our company is successfully running in South Yorkshire, providing wide range of electrical solutions. Please use "contact us" for any inquiries.';
    }

    this.state = {
      title: title,
      description: description,
    };
  }

  render() {
    const { title, description } = this.state;

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://yourapp.com" />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content="https://choiceofledlighting.co.uk/" />
        <meta property="og:site_name" content="Choice of LED lighting" />
      //  <meta property="og:image" content="yourimage.jpg" />
      //  <meta property="og:image:secure_url" content="yourimage.jpg" />
      //  <meta property="og:image:width" content="1280" />
      //  <meta property="og:image:height" content="720" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:title" content={title} />
      //  <meta name="twitter:image" content="yourimage.png" />
      </Helmet>
    );
  }
}

export default HelmetWrapper;
