/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function Location({ id }) {
  return (
    <>
      <div
        className="page-header section-dark location"
        style={{
          backgroundImage: "url(" + require("../assets/img/background.jpg") + ")",

        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="title-brand">
              <h1 className="location-title">
                {id === 'about-us' && (
                  'About Us'
                )}
                {id === 'areas-we-work' && (
                  'Areas We Work In'
                )}

                {id === 'residential' && (
                  'Residential Ligthing'
                )}
                {id === 'commercial' && (
                  'Commercial Ligthing'
                )}
                {id === 'electrical' && (
                  'Electrical services'
                )}
                {id === 'security' && (
                  'Fire & Security Systems'
                )}
                {id === 'gallery' && (
                  'Gallery'
                )}
                {id === 'comments' && (
                  'Comments'
                )}
                {id === 'contact-us' && (
                  'Contact Us'
                )}
              </h1>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Location;
