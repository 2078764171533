import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

class LabourList extends Component {
  static propTypes = {
    member: PropTypes.shape({
      firstName: PropTypes.string,
      email: PropTypes.string,
    })
  }

  static defaultProps = {
    member: {},
  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    const { member, labourList } = this.props;

    return (
      <Row>
        <Col sm="12" style={{ display: 'inline-block' }}>
          <h2> Labour price list (+VAT)</h2>
          {labourList && Object.keys(labourList).map((key) => (
            <Row>
              <Col sm="5">
                {labourList[key].name}
              </Col>
              <Col sm="4">
                {labourList[key].price}
              </Col>
              <Col sm="2" />
            </Row>
          ))}
          <br/>
          <p style={{ fontSize: 13 }}>All prices above are calculated considering that wiring is in place.</p>

          <p style={{ fontSize: 13 }}>Call out price is £50 +VAT for the first hour.</p>
        </Col>
      </Row>
    );
  }
}

export default LabourList;
