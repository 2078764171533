import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

const OPTIONS = {
  "a": { name: "LED light 1", price: 100 },
  "b": { name: "LED light 2", price: 50 },
  "c": { name: "LED light strips", price: 20 },
  "d": { name: "Security systems", price: 200 },
  "e": { name: "Wiring", price: 1 },
  "f": { name: "Office lights", price: 75 },
  "g": { name: "Fluorescent lights", price: 60 },
};

class Calculator extends Component {
  static propTypes = {
    member: PropTypes.shape({
      firstName: PropTypes.string,
      email: PropTypes.string,
    })
  }

  static defaultProps = {
    member: {},
  }

  constructor(props) {
    super(props);

    this.state = {
      amount: 1,
      product: 'a',
      result: 0,
      allSelected: [],
    };
  }

  setSelectionOpen = () => {
    this.setState(prevState => ({
      selectionOpen: !prevState.selectionOpen,
    }));
  }

  setProductOption = (key) => {
    this.setState({ product: key });
  }

  setNextOption = () => {
    const { calculatorOptions } = this.props;
    const { product, amount, allSelected, result } = this.state;

    const { price, name } = calculatorOptions[product];

    const newAllSelected = [...allSelected, { product: name, amount, total: price * amount }]

    this.setState((prevState) => ({
      product: Object.keys(calculatorOptions)[0],
      amount: 1,
      selectionOpen: false,
      allSelected: newAllSelected,
      result: (result || 0) + (price * amount),
    }));
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { member, calculatorOptions } = this.props;
    const { result, product, allSelected, selectionOpen, amount } = this.state;

    console.log(result);

    return (
      <Row>
        <Col sm="12" style={{ display: 'inline-block' }}>
          <h2> Estimate the price </h2>
          <Row style={{ fontWeight: 700 }}>
            <Col sm="5" className="rooms-table-select-type">
              Product
            </Col>
            <Col sm="2">
              Amount
            </Col>
            <Col sm="2">
              Total sum
            </Col>
            <Col sm="2" />
          </Row>
            {allSelected && allSelected.map(item => (
              <Row>
                <Fragment>
                  <Col sm="5">
                    {item.product}
                  </Col>
                  <Col sm="2">
                    {item.amount}
                  </Col>
                  <Col sm="2">
                    {item.total}
                  </Col>
                  <Col sm="2" />
                </Fragment>
              </Row>
            ))}
            <Row>
              <Col sm="5" style={{ display: 'inline-block' }} className="rooms-table-select-type">
                {calculatorOptions && (
                  <Dropdown isOpen={selectionOpen} toggle={this.setSelectionOpen}>
                    <DropdownToggle caret style={{ width: '95%' }}>
                      {calculatorOptions[product].name}
                    </DropdownToggle>
                    <DropdownMenu>
                      {Object.keys(calculatorOptions).map((key) => {
                        return <DropdownItem key={key} onClick={(e) => {this.setProductOption(key)}}>{calculatorOptions[key].name}</DropdownItem>
                      })}
                    </DropdownMenu>
                  </Dropdown>
                )}
              </Col>
              <Col sm="2" style={{ display: 'inline-block' }}>
                <Input className="comments-input" type="number" name="amount" id="amount" value={amount} onChange={this.handleChange} />
              </Col>
              <Col sm="2" style={{ display: 'inline-block', paddingTop: 10 }}>
                {product && amount && calculatorOptions[product] ? (
                  calculatorOptions[product].price * amount
                ):(
                  null
                )}
              </Col>
              <Col sm="2" style={{ display: 'inline-block' }}>
                <Button className="btn-round btn btn-danger" onClick={this.setNextOption} >Add</Button>
              </Col>
            </Row>
        </Col>
        {result ? (
          <Col sm="12">
            {`Total - ${result}`}
          </Col>
        ):(
          null
        )}
      </Row>
    );
  }
}

export default Calculator;
