import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Page from '../web/views/Index';
import Helmet from '../web/components/UI/HelmetWrapper';

class PageContainer extends Component {
  static propTypes = {
    fetchSlideTexts: PropTypes.func.isRequired,
  //  fetchCalculatorOptions: PropTypes.func.isRequired,
    fetchLabourList: PropTypes.func.isRequired,
    page: PropTypes.shape({}),
  }

  static defaultProps = {
    page: {},
  }

  constructor(props) {
    super(props);

    this.state = {
      contentLoaded: false,
    };
  }

  componentDidMount() {
    const { fetchSlideTexts, fetchCalculatorOptions, fetchLabourList } = this.props;

  //  fetchMember();
  //  fetchCalculatorOptions();
    fetchLabourList();
    fetchSlideTexts();
  /*  .then(() => {
      this.setState({ contentLoaded: true });
    }).catch(err => {
      this.setState({ contentLoaded: true });
    }) */
  }

  componentDidUpdate (nextProps) {
  }

  render = () => {
    const { page } = this.props;
    const { contentLoaded } = this.state;

  //  if (contentLoaded) {
      return (
        <>
          <Helmet id="home" />
          <Page
            slideTexts={page.slideTexts}
            labourList={page.labourList}
            contentLoaded={contentLoaded}
          />
        </>
      );
  //  }

  //  return (
  //    <div style={{ minHeight: '100vh' }}/>
  //  );
  }
}

const mapStateToProps = state => ({
  page: state.page || {},
});

const mapDispatchToProps = dispatch => ({
  fetchSlideTexts: dispatch.page.fetchSlideTexts,
//  fetchCalculatorOptions: dispatch.page.fetchCalculatorOptions,
  fetchLabourList: dispatch.page.fetchLabourList,
});

export default connect(mapStateToProps, mapDispatchToProps)(PageContainer);
