import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Member extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    memberLogout: PropTypes.func.isRequired,
    fetchMember: PropTypes.func.isRequired,
    member: PropTypes.shape({}),
  }

  static defaultProps = {
    member: {},
  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount = () => this.fetchData();

  fetchData = (data) => {
    const { fetchMember } = this.props;

    return fetchMember(data);
  }

  render = () => {
    const { Layout, member, memberLogout } = this.props;

    console.log('member');
    console.log(member);

    return (
      <Layout
        member={member}
        logout={memberLogout}
        reFetch={() => this.fetchData()}
      />
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
});

const mapDispatchToProps = dispatch => ({
  memberLogout: dispatch.member.logout,
  fetchMember: dispatch.member.getMemberData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Member);
