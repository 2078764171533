import React, { Component } from 'react';
import PropTypes from 'prop-types';

import GoogleMapReact from 'google-map-react';

import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
} from 'reactstrap';

import Location from '../Location';

class ContactUs extends Component {
  static propTypes = {
    getComments: PropTypes.func.isRequired,
    writeComment: PropTypes.func.isRequired,
    member: PropTypes.shape(),
    comments: PropTypes.shape(),
  }

  static defaultProps = {
    comments: {},
    member: {},
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      showConfirmation: false,
      name: '',
      email: '',
      phone: '',
      message: '',
    };
  }

  componentDidMount() {
  }

  sendMessage = () => {
    const { sendMessage } = this.props;
    const { name, email, phone, message } = this.state;
    if (name && (email || phone) && message) {
      sendMessage({ name, email, phone, message }).then((res) => {
        console.log(res);

        this.setState({
          showConfirmation: true,
          name: '',
          email: '',
          phone: '',
          message: '',
        })
      })
    }
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value, showConfirmation: false });

  render() {
    const { member, comments } = this.props;
    const { showConfirmation, name, email, phone, message } = this.state;

    return (
      <>
      <Location id='contact-us' />
      <Container className="contact-us">
      <Row>
        {showConfirmation ? (
          <Col sm="12">
            <Alert color="success" style={{ fontWeight: 600 }}>Thank you, your message has been sent!</Alert>
          </Col>
        ):(null)}
        <Col sm="6">
          <FormGroup className="contact-us-input-group">
            <Label for="name" style={{ fontWeight: 600 }}>Name</Label>
            <Input className="contact-us-input" type="text" name="name" id="name" value={name} onChange={this.handleChange} />
          </FormGroup>
          <FormGroup>
            <Label for="email" style={{ fontWeight: 600 }}>Email</Label>
            <Input className="contact-us-input" type="email" name="email" id="email" value={email} onChange={this.handleChange} />
          </FormGroup>
          <FormGroup>
            <Label for="phone" style={{ fontWeight: 600 }}>Phone</Label>
            <Input className="contact-us-input" type="phone" name="phone" id="phone" value={phone} onChange={this.handleChange} />
          </FormGroup>
          <FormGroup>
            <Label for="message" style={{ fontWeight: 600 }}>Message</Label>
            <Input className="contact-us-input contact-us-input-comment" type="message" value={message} name="message" id="message" onChange={this.handleChange} />
          </FormGroup>
          <Button className="btn-round btn btn-danger contact-us-send" onClick={this.sendMessage}>Send</Button>
        </Col>
        <Col sm="6">
          <Row>
            <Col style={{ paddingBottom: 5 }}>
              <b style={{ fontWeight: 600, display: 'inline-block' }}>Email:</b> choiceofledlighting@gmail.com
            </Col>
          </Row>
          <Row>
            <Col style={{ paddingBottom: 5 }}>
              <b style={{ fontWeight: 600, display: 'inline-block' }}>Phone:</b> 07498286628, 07425928179
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ height: '400px', width: '100%' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: 'AIzaSyCJiFidvzfXoLmSXij_kpCf8U4Zf53uXRc' }}
                  defaultCenter={{
                    lat: 53.379319447279414,
                    lng: -1.4683296897923284
                  }}
                  defaultZoom={11}
                >
                </GoogleMapReact>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      </Container>
      </>
    )
  }
}

export default ContactUs;
