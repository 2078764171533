import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Comments extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    getComments: PropTypes.func.isRequired,
    writeComment: PropTypes.func.isRequired,
    gallery: PropTypes.shape({}),
  }

  static defaultProps = {
    member: {},
  }

  constructor(props) {
    super(props);

    this.state = {
    //  error: null,
    //  loading: false,
    };
  }

  componentDidMount = () => {
  }

  componentDidUpdate (nextProps) {
  }

  render = () => {
    const { Layout, comments, getComments, writeComment } = this.props;

    return (
      <Layout
        comments={comments.comments}
        getComments={getComments}
        writeComment={writeComment}
      />
    );
  }
}

const mapStateToProps = state => {
  return ({
    comments: state.comments || {},
  })
};

const mapDispatchToProps = dispatch => ({
  getComments: dispatch.comments.getComments,
  writeComment: dispatch.comments.writeComment,
});

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
