import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';

import Location from '../Location';

class Comments extends Component {
  static propTypes = {
    getComments: PropTypes.func.isRequired,
    writeComment: PropTypes.func.isRequired,
    member: PropTypes.shape(),
    comments: PropTypes.shape(),
  }

  static defaultProps = {
    comments: {},
    member: {},
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      comment: '',
      name: '',
    };
  }

  componentDidMount() {
    const { getComments } = this.props;

    getComments();
  }

  sendComment = () => {
    const { writeComment } = this.props;
    const { comment, name } = this.state;
    if (comment && name) {
      writeComment({ comment, name });
    }
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { member, comments } = this.props;
  //  const { isOpen } = this.state;

    return (
      <>
      <Location id='comments' />
      <Container className="comments">
        <Row>
          {comments && Object.keys(comments).map((key) => {
            const date = new Date(comments[key].date);

            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return(
            <Col sm="12" className="comments-item">
              <Row>
                <Col sm="6">
                  <div className="comments-item-name" style={{ display: 'inline-block' }}>{comments[key].name} </div>
                  <div className="comments-item-date" style={{ display: 'inline-block' }}>
                    {`${day}-${month}-${year}`}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={{ offset: 1, size: 11 }} className="comments-item-text"> {comments[key].text} </Col>
              </Row>
              {comments[key].response && (
                <Row>
                  <Col sm="12" className="comments-item-name"> Atbilde </Col>
                  <Col sm={{ offset: 2, size: 10 }} className="comments-item-reponse"> {comments[key].response} </Col>
                </Row>
              )}
            </Col>
          )})}
        </Row>
        <Row className="comments-leave">
          Leave A Comment
        </Row>
        <Row>
        <FormGroup className="comments-input-group">
          <Label for="name">Name</Label>
          <Input className="comments-input" type="text" name="name" id="name" placeholder="" onChange={this.handleChange} />
        </FormGroup>
        <FormGroup>
          <Label for="comment">Comment</Label>
          <Input className="comments-input comments-input-comment" type="text" name="comment" id="comment" placeholder="Write a comment" onChange={this.handleChange} />
        </FormGroup>
        <Button className="btn-round btn btn-danger comments-send" onClick={this.sendComment} > Send </Button>
        </Row>
      </Container>
      </>
    )
  }
}

export default Comments;
